ol li {
  list-style-type: decimal;
  padding-bottom: 5px;
  padding-top: 5px;
  color: #4D4F53;
}

ol li strong{
  font-weight: bold;
  color: #000000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0.9;
  object-fit: contain;
  background:linear-gradient(0deg, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7)), url('../../public/bg.jpg');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.overlay-auth {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0.9;
  object-fit: contain;
  background:linear-gradient(0deg, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7)), url('../../public/cafe-bg.jpg');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}